<template>
  <small class="form-text text-danger" v-if="message">
    {{ message }}
  </small>
</template>
<script>
export default {
  name: "ErrorMessage",
  props: ["message"]
};
</script>

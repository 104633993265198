<template>
  <div>
    <b-overlay :show="isOverlay || kycKybDetailsLoading" rounded="sm">
      <template v-if="isPendingOperationApproval">
        <div class="card min-vh-100">
          <div class="card-header row justify-content-center">
            <b-alert class="text-center w-75 font-weight-bold" show
              ><b-icon
                icon="info-circle-fill"
                class="mr-2"
                aria-hidden="true"
              ></b-icon
              >{{ $t("kyc_kyb.waiting_status_text") }}</b-alert
            >
          </div>
        </div>
      </template>
      <div class="card" v-else>
        <div
          class="card-header row justify-content-center"
          v-if="kycKybDetails && isRejected"
        >
          <b-alert
            variant="danger"
            class="w-75 text-center font-weight-bold"
            show
            ><b-icon
              icon="exclamation-triangle-fill"
              class="mr-2"
              aria-hidden="true"
            ></b-icon
            >{{ $t("kyc_kyb.rejected_status_text") }}</b-alert
          >
        </div>
        <div class="card-header text-center pl-0 border-0">
          <b-button-group class="w-50">
            <b-button
              class="ml-0"
              :variant="isKyc ? 'info' : 'outline-info'"
              @click="setActiveTab('kyc')"
              >KYC</b-button
            >
            <b-button
              :variant="!isKyc ? 'info' : 'outline-info'"
              @click="setActiveTab('kyb')"
              >KYB</b-button
            >
          </b-button-group>
        </div>
        <div class="card-body min-vh-100" v-if="isKyc">
          <div
            class="d-flex mb-2 flex-wrap align-items-end justify-content-center"
          >
            <div class="col-12 col-md-3">
              <label>{{ $t("FORM.FIRST_NAME") }}</label>
            </div>
            <div class="col-12 col-md-3">
              <label>{{ $t("FORM.LAST_NAME") }}</label>
            </div>
            <div class="col-12 col-md-3">
              <label>{{ $t("FORM.EMAIL") }}</label>
            </div>
            <div class="col-12 col-md-1">
              <b-button variant="outline-primary" @click="addRowToKycFields"
                ><b-icon icon="plus" aria-hidden="true"></b-icon
              ></b-button>
            </div>
          </div>

          <div
            class="d-flex flex-wrap justify-content-center mb-5"
            v-for="(field, fieldIdx) in $v.kycFormFields.$each.$iter"
            :key="`kyc-field-${fieldIdx}`"
          >
            <div class="col-12 col-md-3">
              <input
                type="text"
                class="form-control"
                v-model.trim="field.name.$model"
              />
              <small
                class="form-text text-danger"
                v-if="field.name.$error & !field.name.required"
              >
                {{ $t("VALIDATION.REQUIRED_FIELD") }}
              </small>
            </div>
            <div class="col-12 col-md-3">
              <input
                type="text"
                class="form-control"
                v-model="field.surname.$model"
              />
              <small
                class="form-text text-danger"
                v-if="field.surname.$error && !field.surname.required"
              >
                {{ $t("VALIDATION.REQUIRED_FIELD") }}
              </small>
            </div>
            <div class="col-12 col-md-3">
              <input
                type="email"
                class="form-control"
                v-model="field.email.$model"
              />
              <template v-if="field.email.$error">
                <small
                  class="form-text text-danger"
                  v-if="!field.email.required"
                >
                  {{ $t("VALIDATION.REQUIRED_FIELD") }}
                </small>
                <small class="form-text text-danger" v-if="!field.email.email">
                  {{ $t("VALIDATION.EMAIL_NOT_VALID") }}
                </small>
              </template>
            </div>
            <div class="col-12 col-md-1">
              <b-button
                variant="outline-danger"
                v-if="kycFormFields.length > 1 && fieldIdx !== 0"
                @click="removeFromKycFields(fieldIdx)"
                ><b-icon icon="x" aria-hidden="true"></b-icon
              ></b-button>
              <b-button variant="outline-secondary" v-else
                ><b-icon icon="" aria-hidden="true"></b-icon
              ></b-button>
            </div>
          </div>
          <div class="col-12 text-center">
            <div
              class="form-text text-danger mb-2"
              v-if="!$v.kycFormFields.isUnique"
            >
              {{ $t("VALIDATION.DUPLICATED_VALUES", { field: "Email" }) }}
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <b-button variant="outline-secondary mr-2" disabled
              ><b-icon icon="chevron-double-left"></b-icon
              >{{ $t("COMMON.previous") }}</b-button
            >
            <b-button
              variant="outline-primary"
              :disabled="$v.kycFormFields.$anyError"
              @click="onContinue"
              >{{ $t("COMMON.next")
              }}<b-icon icon="chevron-double-right"></b-icon
            ></b-button>
          </div>
        </div>
        <div class="card-body p-0 min-vh-100" v-else>
          <div class="d-flex flex-wrap justify-content-center">
            <div class="form-group col-12 col-md-9">
              <div class="d-flex justify-content-between align-items-end mb-1">
                <label class="col-form-label">{{
                  $t("kyc_kyb.commerceNumber")
                }}</label>
                <b-button
                  variant="outline-primary"
                  @click="addRowToCommerceNumbers"
                  ><b-icon icon="plus" aria-hidden="true"></b-icon
                ></b-button>
              </div>
              <div
                class="d-flex justify-content-between mb-2"
                v-for="(commerceItem, commerceItemIdx) in $v.kybFormFields
                  .commerceNumber.$each.$iter"
                :key="`commerceItem-${commerceItemIdx}`"
              >
                <div class="col-10 px-0">
                  <input
                    type="text"
                    class="form-control"
                    v-model="commerceItem.num.$model"
                  />
                  <template v-if="commerceItem.num.$error">
                    <small
                      class="form-text text-danger"
                      v-if="!commerceItem.num.required"
                    >
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                    </small>
                    <small
                      class="form-text text-danger"
                      v-if="
                        `${commerceItemIdx}` ===
                          `${kybFormFields.commerceNumber.length - 1}` &&
                          !commerceItem.num.isUnique
                      "
                    >
                      {{
                        $t("VALIDATION.DUPLICATED_VALUES", { field: "Num." })
                      }}
                    </small>
                  </template>
                </div>
                <div class="">
                  <b-button
                    variant="outline-danger"
                    v-if="
                      kybFormFields.commerceNumber.length > 1 &&
                        commerceItemIdx !== 0
                    "
                    @click="removeFromCommerceNumbers(commerceItemIdx)"
                    ><b-icon icon="x" aria-hidden="true"></b-icon
                  ></b-button>
                  <b-button variant="outline-secondary" v-else
                    ><b-icon icon="" aria-hidden="true"></b-icon
                  ></b-button>
                </div>
              </div>
            </div>
            <div class="form-group col-12 col-md-9">
              <label class="col-form-label">{{
                $t("kyc_kyb.taxNumber")
              }}</label>
              <input
                type="text"
                class="form-control"
                v-model="$v.kybFormFields.taxNumber.$model"
              />
              <small
                class="form-text text-danger"
                v-if="
                  $v.kybFormFields.taxNumber.$error &&
                    !$v.kybFormFields.taxNumber.required
                "
              >
                {{ $t("VALIDATION.REQUIRED_FIELD") }}
              </small>
            </div>

            <div class="form-group col-12 col-md-9">
              <label class="col-form-label"
                >{{ $t("kyc_kyb.files_ubo") }}
                <span class="pdf-image-info-text">{{
                  `(PDF, PNG, JPG, JPEG)`
                }}</span></label
              >
              <div class="form-control border-0 pl-0 mb-4">
                <FileUploadButton @change="onChangeFile($event, 'uboFile')" />
              </div>
              <FileCardList
                :files="kybFormFields.uboFile"
                @onRemoveFile="removeFile($event, 'uboFile')"
              />
              <ErrorMessage :message="customErrors?.uboFile" />
            </div>

            <div class="form-group col-12 col-md-9">
              <label class="col-form-label"
                >{{ $t("kyc_kyb.id_cards_including_ubos")
                }}<span class="pdf-image-info-text">{{
                  `(PDF, PNG, JPG, JPEG)`
                }}</span></label
              >
              <div class="form-control border-0 pl-0 mb-4">
                <FileUploadButton
                  :multiple="true"
                  :label="$t('COMMON.browse_files')"
                  @change="onChangeFile($event, 'idCardsIncludingUbosFiles')"
                />
              </div>
              <FileCardList
                :files="kybFormFields.idCardsIncludingUbosFiles"
                @onRemoveFile="removeFile($event, 'idCardsIncludingUbosFiles')"
              />
              <ErrorMessage
                :message="customErrors?.idCardsIncludingUbosFiles"
              />
            </div>

            <div class="form-group col-12 col-md-9">
              <label class="col-form-label"
                >{{ $t("kyc_kyb.files_companyActivities")
                }}<span class="pdf-image-info-text">{{
                  `(PDF, PNG, JPG, JPEG)`
                }}</span></label
              >
              <div class="form-control border-0 pl-0 mb-4">
                <FileUploadButton
                  @change="onChangeFile($event, 'companyActivitiesFile')"
                />
              </div>
              <FileCardList
                :files="kybFormFields.companyActivitiesFile"
                @onRemoveFile="removeFile($event, 'companyActivitiesFile')"
              />
              <ErrorMessage :message="customErrors?.companyActivitiesFile" />
            </div>

            <div class="form-group col-12 col-md-9">
              <label class="col-form-label"
                >{{ $t("kyc_kyb.sourceOfFund")
                }}<span class="pdf-image-info-text">{{
                  `(PDF, PNG, JPG, JPEG)`
                }}</span></label
              >
              <b-form-checkbox
                v-model="$v.kybFormFields.sourceOfFund.$model"
                @change="kybFormFields.sourceOfFundFiles = []"
                size="lg"
                switch
              >
              </b-form-checkbox>
              <template v-if="$v.kybFormFields.sourceOfFund.$model">
                <div class="form-control border-0 pl-0 mb-4">
                  <FileUploadButton
                    @change="onChangeFile($event, 'sourceOfFundFiles')"
                    :multiple="true"
                    :label="$t('COMMON.browse_files')"
                  />
                </div>
                <FileCardList
                  :files="kybFormFields.sourceOfFundFiles"
                  @onRemoveFile="removeFile($event, 'sourceOfFundFiles')"
                />
                <ErrorMessage :message="customErrors?.sourceOfFundFiles" />
              </template>
            </div>

            <div class="form-group col-12 col-md-9">
              <label class="col-form-label"
                >{{ $t("kyc_kyb.files_orgChart")
                }}<span class="pdf-image-info-text">{{
                  `(PDF, PNG, JPG, JPEG)`
                }}</span></label
              >
              <div class="form-control border-0 pl-0 mb-4">
                <FileUploadButton
                  @change="onChangeFile($event, 'orgChartFiles')"
                  :multiple="true"
                  :label="$t('COMMON.browse_files')"
                />
              </div>
              <FileCardList
                :files="kybFormFields.orgChartFiles"
                @onRemoveFile="removeFile($event, 'orgChartFiles')"
              />
              <ErrorMessage :message="customErrors?.orgChartFiles" />
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <b-button variant="outline-danger mr-2" @click="activeTab = 'kyc'"
              ><b-icon icon="chevron-double-left"></b-icon
              >{{ $t("COMMON.previous") }}</b-button
            >
            <b-button
              variant="success"
              :disabled="$v.kybFormFields.$anyError"
              @click="onSubmit"
              >{{ $t("FORM.SUBMIT") }}<b-icon icon="check"></b-icon
            ></b-button>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import FileCardList from "@/components/common/FileCardList";
import FileUploadButton from "@/components/common/FileUploadButton";
import ErrorMessage from "@/components/common/ErrorMessage";

const maxFileSizeInBytes = 1024 * 1024 * 15; // 15 MB
const allowedFileTypes = [
  "application/pdf",
  "image/png",
  "image/jpeg",
  "image/jpg"
];

const fileRequired = value => {
  if (!value || value.length === 0) return true;
  return false;
};

const fileSizeValidator = value => {
  return value.size > maxFileSizeInBytes;
};

const fileTypeValidator = value => {
  return !allowedFileTypes.includes(value.type) ? true : false;
};

export default {
  name: "KycKyb",
  mixins: [validationMixin],
  components: { FileCardList, FileUploadButton, ErrorMessage },
  data: function() {
    return {
      isOverlay: false,
      activeTab: "kyc",
      kycFormFields: [{ name: "", surname: "", email: "" }],
      kybFormFields: {
        commerceNumber: [{ num: "" }],
        taxNumber: null,
        uboFile: [],
        idCardsIncludingUbosFiles: [],
        companyActivitiesFile: [],
        sourceOfFund: false,
        sourceOfFundFiles: [],
        orgChartFiles: []
      },
      customErrors: {}
    };
  },
  validations() {
    if (this.isKyc) {
      return {
        kycFormFields: {
          required,
          $each: {
            name: {
              required
            },
            surname: {
              required
            },
            email: {
              required,
              email
            }
          },
          isUnique() {
            const emails = this.kycFormFields.map(item => item.email);
            const isDuplicate = emails.some(
              (item, idx) => emails.indexOf(item) != idx
            );
            return !isDuplicate;
          }
        }
      };
    }
    return {
      kybFormFields: {
        commerceNumber: {
          required,
          $each: {
            num: {
              required,
              isUnique() {
                const commerceNumbers = this.kybFormFields.commerceNumber.map(
                  item => item.num
                );
                const isDuplicate = commerceNumbers.some(
                  (item, idx) => commerceNumbers.indexOf(item) != idx
                );
                return !isDuplicate;
              }
            }
          }
        },
        taxNumber: { required },
        uboFile: {},
        idCardsIncludingUbosFiles: {},
        companyActivitiesFile: {},
        sourceOfFund: {},
        sourceOfFundFiles: {},
        orgChartFiles: {}
      }
    };
  },
  computed: {
    ...mapGetters([
      "activeUser",
      "userRole",
      "isMerchant",
      "isDistributor",
      "isBranch"
    ]),
    ...mapState({
      kycKybDetailsLoading: state => state.company.kycKybDetailsLoading,
      kycKybDetails: state => state.company.kycKybDetails,
      kycFilesStatus: state => state.company.kycFilesStatus
    }),
    companyId() {
      return this.activeUser?.parent?.id;
    },
    isKyc() {
      return this.activeTab === "kyc";
    },
    isPendingOperationApproval() {
      return this.kycFilesStatus === "pending";
    },
    isRejected() {
      return this.kycFilesStatus === "rejected";
    }
  },
  methods: {
    setActiveTab(tab) {
      if (this.activeTab !== tab) {
        if (tab === "kyb") {
          this.$v.kycFormFields.$touch();
          if (this.$v.kycFormFields.$anyError) {
            return;
          }
        }
        this.activeTab = tab;
      }
    },
    addRowToKycFields() {
      this.$v.kycFormFields.$touch();
      if (this.$v.kycFormFields.$anyError) {
        return;
      }
      this.kycFormFields.push({ name: "", surname: "", email: "" });
    },
    removeFromKycFields(index) {
      this.kycFormFields.splice(index, 1);
    },
    onContinue() {
      this.$v.kycFormFields.$touch();
      if (this.$v.kycFormFields.$anyError) {
        return;
      }
      this.setActiveTab("kyb");
    },
    addRowToCommerceNumbers() {
      this.$v.kybFormFields.commerceNumber.$touch();
      if (this.$v.kybFormFields.commerceNumber.$anyError) {
        return;
      }
      this.kybFormFields.commerceNumber.push({ num: "" });
    },
    removeFromCommerceNumbers(idx) {
      this.kybFormFields.commerceNumber.splice(idx, 1);
    },
    resetForm() {
      this.activeTab = "kyc";
      this.kycFormFields = [{ name: "", surname: "", email: "" }];
      this.kybFormFields = {
        commerceNumber: [{ num: "" }],
        taxNumber: null,
        uboFile: [],
        idCardsIncludingUbosFiles: [],
        companyActivitiesFile: [],
        sourceOfFund: false,
        sourceOfFundFiles: [],
        orgChartFiles: []
      };
      this.$v?.kycFormFields?.$reset();
      this.$v?.kybFormFields?.$reset();
    },
    onSubmit() {
      this.$v.kybFormFields.$touch();
      if (this.$v.kybFormFields.$anyError) {
        return;
      }
      if (
        this.fileHasError("uboFile") ||
        this.fileHasError("idCardsIncludingUbosFiles") ||
        this.fileHasError("companyActivitiesFile") ||
        this.fileHasError("sourceOfFundFiles") ||
        this.fileHasError("orgChartFiles")
      ) {
        return;
      }
      const company = this.companyId;
      const names = this.kycFormFields.map(val => val.name);
      const surnames = this.kycFormFields.map(val => val.surname);
      const emails = this.kycFormFields.map(val => val.email);
      const commerceNumbers = this.kybFormFields.commerceNumber.map(
        val => val.num
      );
      const {
        taxNumber,
        uboFile,
        idCardsIncludingUbosFiles,
        companyActivitiesFile,
        sourceOfFund,
        sourceOfFundFiles,
        orgChartFiles
      } = this.kybFormFields;
      const formData = new FormData();
      // strings with FormData
      formData.append("company", company);
      formData.append("taxNumber", taxNumber);
      // arrays, objects with FormData
      names.forEach(nameItem => {
        formData.append("name[]", nameItem);
      });
      surnames.forEach(surnameItem => {
        formData.append("surname[]", surnameItem);
      });
      emails.forEach(emailItem => {
        formData.append("email[]", emailItem);
      });
      commerceNumbers.forEach(numItem => {
        formData.append("commerceNumber[]", numItem);
      });
      formData.append("ubo", uboFile[0]);
      idCardsIncludingUbosFiles.forEach(file => {
        formData.append("idCard[]", file);
      });
      formData.append("companyActivities", companyActivitiesFile[0]);
      formData.append("sourceOfFund", sourceOfFund);
      if (sourceOfFund) {
        sourceOfFundFiles.forEach(file => {
          formData.append("sourceOfFundFile[]", file);
        });
      }
      orgChartFiles.forEach(file => {
        formData.append("orgChart[]", file);
      });
      this.isOverlay = true;
      this.$store
        .dispatch("company/SUBMIT_KYC_KYB", formData)
        .then(({ data }) => {
          this.isOverlay = false;
          if (!data?.success) {
            this.$root.$bvToast.toast(this.$t("FORM.ERROR"), {
              title: this.$t("FORM.ERROR_TITLE"),
              variant: "danger",
              autoHideDelay: 5000
            });
            return;
          }
          this.getKycKyb(this.companyId);
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
          this.resetForm();
          this.$root.$bvToast.toast(this.$t("kyc_kyb.submitted"), {
            title: this.$t("FORM.SUCCESS_TITLE"),
            variant: "success",
            autoHideDelay: 5000
          });
        })
        .catch(error => {
          console.log(error);
          this.isOverlay = false;
          const message =
            error.response?.data?.message || this.$t("FORM.ERROR");
          this.$root.$bvToast.toast(message, {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    },
    fileHasError(keyName) {
      let hasError = false;
      this.customErrors = {};
      const requiredFileKeys = [
        "uboFile",
        "idCardsIncludingUbosFiles",
        "companyActivitiesFile",
        "orgChartFiles"
      ];
      if (this.kybFormFields.sourceOfFund) {
        requiredFileKeys.push("sourceOfFundFiles");
      }
      if (!requiredFileKeys.includes(keyName)) {
        return false;
      }
      if (fileRequired(this.kybFormFields[keyName])) {
        this.customErrors[keyName] = this.$t("VALIDATION.REQUIRED_FIELD");
        return true;
      }
      this.kybFormFields[keyName].forEach(file => {
        if (fileSizeValidator(file)) {
          this.customErrors[keyName] = this.$t(
            "VALIDATION.FILE_SIZE_LESS_THAN",
            { size: "15MB" }
          );
          hasError = true;
        }
        if (fileTypeValidator(file)) {
          this.customErrors[keyName] = this.$t(
            "VALIDATION.ACCEPTED_FILE_TYPES",
            {
              types: "PDF, PNG, JPG, JPEG"
            }
          );
          hasError = true;
        }
      });
      return hasError;
    },
    onChangeFile(e, keyName) {
      this.customErrors = {
        ...this.customErrors,
        [keyName]: null
      };
      const files = e.target.files || e.dataTransfer.files;
      if (files.length > 0) {
        this.kybFormFields[keyName] = Array.from(files);
      }
    },
    removeFile(fileIdx, keyName) {
      this.kybFormFields[keyName]?.splice(fileIdx, 1);
    },
    getKycKyb(companyId) {
      this.$store
        .dispatch("company/GET_KYC_KYB", companyId)
        .then(() => {
          this.kycFormFields = this.kycKybDetails.kycFields;
          this.kybFormFields.commerceNumber = this.kycKybDetails.commerceNumber;
          this.kybFormFields.taxNumber = this.kycKybDetails.taxNumber;
          this.kybFormFields.sourceOfFund = this.kycKybDetails.sourceOfFund;
        })
        .catch(() => {});
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "KYC-KYB" }]);
    if (this.companyId) {
      this.getKycKyb(this.companyId);
    }
  },
  watch: {
    companyId: function(val) {
      if (val && !this.kycKybDetails) {
        this.getKycKyb(val);
      }
    },
    activeTab: function(value) {
      if (value && value === "kyc") {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.card {
  border-radius: 0;
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  padding: 25px 35px 25px 30px;
}
label {
  font-weight: bold;
}
.pdf-image-info-text {
  text-decoration: underline;
  color: #8950fc !important;
  font-weight: 700 !important;
  height: 16px !important;
  padding-left: 10px;
}
</style>

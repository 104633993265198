<template>
  <span class="btn btn-outline-info round btn-file">
    <b-icon
      icon="cloud-upload-fill"
      aria-hidden="true"
      font-scale="0.9"
      style="vertical-align: middle;"
    ></b-icon>
    {{ label }}
    <input
      @change="$event => $emit('change', $event)"
      type="file"
      :multiple="multiple"
    />
  </span>
</template>
<script>
import i18n from "@/plugins/vue-i18n";
export default {
  name: "FileUploadButton",
  props: {
    label: {
      type: String,
      default: i18n.t("COMMON.browse")
    },
    multiple: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
</style>

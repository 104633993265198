<template>
  <div v-if="files && files.length > 0" class="row">
    <div
      v-for="(file, index) in files"
      :key="`file-${file.name}-${index}`"
      class="col-12 col-md-3 mb-2"
    >
      <div
        class="card bg-secondary rounded position-relative"
        style="min-height: 10rem;"
      >
        <i
          class="ml-5 fa fa-times text-danger remove-icon"
          @click="$emit('onRemoveFile', index)"
        >
        </i>
        <div class="card-body p-0 d-flex flex-column justify-content-end">
          <i
            class="fa text-primary file-icon"
            :class="getFileIconByType(file?.type)"
          ></i>
          <p class="card-text ml-3 mb-3" style="vertical-align: bottom;">
            {{ file.name }} - {{ formatBytes(file?.size) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatBytes } from "../../utils/file-size";
export default {
  name: "FileCardList",
  props: ["files"],
  methods: {
    formatBytes,
    getFileIconByType(fileType = "") {
      let icon = "fa-file";
      if (!fileType) return icon;
      if (fileType.includes("pdf")) {
        icon = "fa-file-pdf";
      }
      if (fileType.includes("image")) {
        icon = "fa-image";
      }
      return icon;
    }
  }
};
</script>
<style scoped>
.remove-icon {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.file-icon {
  position: absolute;
  /* Center the element within its parent */
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
</style>
